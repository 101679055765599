import { services } from "../../services";
let networkId = 1;
async function fetchNetworkId() {
  networkId = await services.getNetworkId();
}
fetchNetworkId();

function getContractAddresses() {
  if (networkId === "0x61" || +networkId === 97)
    return {
      token: "0x7862A31a1a1184882CEe3Aec91Bcc93B0512D09F",
      nftContractAddress: "0x932a362d89b60FE93e5d84E009E2C956578Ab536",
    };
  else if (+networkId === 56 || networkId === "0x38")
    return {
      token: "0xF70c1addE0d0a56A07bac31dfE096E5BC567bF44",
      nftContractAddress: "0x394a0C3Ac1674bFa7778743f431b4801b51aeF6a",
      escrowContractAddres: "0x224062036E77E54F30c41cE88082BCf93b4A1Bd1",
    };
  else
    return {
      token: "0xF70c1addE0d0a56A07bac31dfE096E5BC567bF44",
      nftContractAddress: "0x394a0C3Ac1674bFa7778743f431b4801b51aeF6a",
      escrowContractAddres: "0x224062036E77E54F30c41cE88082BCf93b4A1Bd1",
    };
}
export default getContractAddresses;
