import * as React from "react";
import { List, Datagrid, TextField, ImageField, BooleanField, ShowButton, Pagination } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';


const bannerRowClick = (id, basePath, record) => record.editable ? 'edit' : 'show';
const BannerShowButton = ({ record }) => (
    <ShowButton basePath="/banners" label="Show" record={record} />
)
const useStyles = makeStyles({
    imgContainer: {
        '& img': {
            height: '60px',
            width: '60px',
            objectFit: 'contain',
            // borderRadius: '50%',
        }
    }
})
const BannerPagination = props => <Pagination rowsPerPageOptions={[]} {...props} />;


const BannerList = (props) => {

    const classes = useStyles();

    return (
        <List {...props}
            title="List of Banners"
            perPage={20}
            pagination={<BannerPagination />}
            bulkActionButtons={false}>
            <Datagrid rowClick={bannerRowClick}>
                <TextField source="id" sortable={false} />
                <ImageField source="banner.en" sortable={false} label="English Version Banner" className={classes.imgContainer} />
                <ImageField source="banner.tu" sortable={false} label="Turkish Version Banner" className={classes.imgContainer} />
                <BooleanField source="isActive" sortable={false} label="Active" />
                <BannerShowButton source="View" />
            </Datagrid>
        </List>
    )
}

export default BannerList;