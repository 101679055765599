import axios from "axios";

const API_URL=process.env.REACT_APP_API_URL;
console.log(API_URL);

// production server
export const api = axios.create({
  baseURL: API_URL,
});

export const apiUrl = API_URL;

// aws s3 bucket confiurations
export const awsRegion = "eu-central-1";
export const awsIdentityPoolId =
  "eu-central-1:949b9487-ed35-4eb3-a2d3-1866e6ad890b";
export const awsBucket = "avangrat-development";
